
import axios from "axios"


const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Authentification Web Service 
 * @returns 
 */

export async function CreateClub(idclub,data) {

    try {
        axios.defaults.headers.common['x-club-name'] = encodeURIComponent(idclub);

        const response = await axios.post(`${url}Club/create`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

export async function insertpassword(data){

    try {
        const response = await axios.post(`${url}Club/insertpassword`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   
  export async function resetpassword(data){
    try {
        const response = await axios.post(`${url}Club/resetpassword`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }
  
export async function getclub(saison){

    try {
        const idclub = localStorage.getItem('idunique');
        const response = await axios.get(`${url}Club/getclub/${idclub}/${saison}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  export async function getclubNumInscription(){

    try {
        const idclub = localStorage.getItem('idunique');
        const response = await axios.get(`${url}Club/getclub/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   
  export async function renouvellementClub(){

    try {
        const idclub = localStorage.getItem('idunique');
        const data={idClub:idclub}
        const response = await axios.put(`${url}Club/renouvellementClub`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  export async function getclubbyid(idclub){

    try {
        const response = await axios.get(`${url}Club/getclub/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  } 
  export async function updatesteps(data){

    try {
        const response = await axios.put(`${url}Club/updateste`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

    
export async function getinfoclub(saison){

    try {
        const idclub = localStorage.getItem('idunique');
        const response = await axios.get(`${url}Club/getinfoclub/${idclub}/${saison}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   
  export async function getcount(saison){

    try {
        const idclub = localStorage.getItem('idunique');
        const response = await axios.get(`${url}Club/getcount/${idclub}/${saison}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   
  export async function updateClub (data) {

    try {

        const response = await axios.put(`${url}Club/updateclub`,data)
        console.log('mmmmmmmmm',response)


    }catch(error){
        console.log('mmmmmmmmm',error)
        return error?.response
    }
  } 


 